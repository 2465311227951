<template>
	<!-- <b-card class="position-relative" no-body> -->
		<div class="d-flex justify-content-between align-items-center">
			<div class="truncate">
				<h2 class="mb-0 font-weight-bolder">
					{{ statistic }}
				</h2>
				<small>{{ statisticTitle }}</small>
			</div>
			<b-avatar :variant="`light-${color}`" size="40">
				<feather-icon :icon="icon" size="25" />
			</b-avatar>
		</div>
	<!-- </b-card> -->
</template>

<script>
	import { BAvatar, BCard, BCardBody } from "bootstrap-vue";

	export default {
		components: {
			BCard,
			BCardBody,
			BAvatar,
		},
		props: {
			icon: {
				type: String,
				required: true,
			},
			statistic: {
				type: [Number, String],
				required: true,
			},
			statisticTitle: {
				type: String,
				default: "",
			},
			color: {
				type: String,
				default: "primary",
			},
		},
	};
</script>
